// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/dashboarding'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  selectiveProcesses: {
    root: path(ROOTS_DASHBOARD, '/listSelectiveProcess'),
    progressCandidate: (candidateId, selectiveProcessID) =>
      path(ROOTS_DASHBOARD, `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}`),
    auxiliaryRequirementsTest: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/auxiliaryRequirementsTest`
      ),
    auxiliaryQuestionsTest: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/auxiliaryQuestionsTest`
      ),
    addVideoInterview: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/addVideoInterview`
      ),
    viewVideoInterview: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/viewVideoInterview`
      ),
    customTestQuestions: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/customTestQuestions`
      ),
    valuesTestIntro: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/valuesTestIntro`
      ),
    valuesTestAnswer: (candidateId, selectiveProcessID) =>
      path(
        ROOTS_DASHBOARD,
        `/users/candidates/${candidateId}/pregressSelectiveProcess/${selectiveProcessID}/valuesTestAnswer`
      )
  },
  sendMessage: {
    root: path(ROOTS_DASHBOARD, '/sendMessage'),
    all: path(ROOTS_DASHBOARD, '/sendMessage/all')
  },
  subscriptions: {
    root: path(ROOTS_DASHBOARD, '/subscriptions')
  },
  plans: {
    root: path(ROOTS_DASHBOARD, '/plans')
  },
  logsEmails: {
    root: path(ROOTS_DASHBOARD, '/logsEmails')
  },
  logsErrosEmails: {
    root: path(ROOTS_DASHBOARD, '/logsErrosEmails')
  },
  logsWhatsapp: {
    root: path(ROOTS_DASHBOARD, '/logsWhatsapp')
  },
  logsCatho: {
    root: path(ROOTS_DASHBOARD, '/logsCatho')
  },
  logging: {
    root: path(ROOTS_DASHBOARD, '/logging')
  },
  emailErrorLogs: {
    root: path(ROOTS_DASHBOARD, '/emailErrorLogs')
  },
  vouchers: {
    root: path(ROOTS_DASHBOARD, '/vouchers')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    weights: path(ROOTS_DASHBOARD, '/settings/weights')
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    superAdmins: path(ROOTS_DASHBOARD, '/users/super-admins'),
    newSuperAdmins: path(ROOTS_DASHBOARD, '/users/super-admins/new'),
    candidate: path(ROOTS_DASHBOARD, '/users/candidates'),
    viewVideoResume: (candidateId) => path(ROOTS_DASHBOARD, `/users/candidates/${candidateId}/viewVideoResume`),
    addVideoResume: (candidateId) => path(ROOTS_DASHBOARD, `/users/candidates/${candidateId}/addVideoResume`),
    competencyTest: (candidateId) => path(ROOTS_DASHBOARD, `/users/candidates/${candidateId}/competencyTest`),
    editCandidate: path(ROOTS_DASHBOARD, '/users/:candidateId/editCandidate'),
    candidateSettings: path(ROOTS_DASHBOARD, '/users/:candidateId/candidateSettings')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  companies: {
    root: path(ROOTS_DASHBOARD, '/companies'),
    valuesTest: (companyId) => path(ROOTS_DASHBOARD, `/companies/${companyId}/valuesTest`),
    register: path(ROOTS_DASHBOARD, '/companies/register'),
    listSelectiveProcesses: path(ROOTS_DASHBOARD, '/companies/:companyId/listSelectiveProcesses'),
    registerSelectiveProcess: path(ROOTS_DASHBOARD, '/companies/:companyId/registerSelectiveProcess'),
    updateSelectiveProcess: path(ROOTS_DASHBOARD, '/companies/:companyId/updateSelectiveProcess'),
    viewSelectiveProcess: path(ROOTS_DASHBOARD, '/companies/:companyId/viewSelectiveProcess/:selectivePocessId'),
    editCompany: path(ROOTS_DASHBOARD, '/companies/:companyId/editCompany'),
    questionSheet: {
      root: path(ROOTS_DASHBOARD, `/companies/:companyId/questionSheet`),
      customTest: path(ROOTS_DASHBOARD, `/companies/:companyId/questionSheet/customTest`)
    }
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
